var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-form"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('ValidationProvider',{staticClass:"input-default",attrs:{"rules":"required|max:16|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordOld),expression:"passwordOld"}],class:{ 'input-error' : errors[0]},attrs:{"type":"password","placeholder":_vm.$t('patient.password.old')},domProps:{"value":(_vm.passwordOld)},on:{"input":function($event){if($event.target.composing){ return; }_vm.passwordOld=$event.target.value}}}),_c('span',{staticClass:"notice-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{ref:"pwd",staticClass:"input-default",attrs:{"rules":"required|max:16|min:8|rgpd_password","vid":"pwd"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],class:{ 'input-error' : errors[0]},attrs:{"type":"password","placeholder":_vm.$t('patient.password.new')},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('span',{staticClass:"notice-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"input-default",attrs:{"rules":"required|max:16|min:8|rgpd_password|confirmed:pwd"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordConfirm),expression:"passwordConfirm"}],class:{ 'input-error' : errors[0]},attrs:{"type":"password","placeholder":_vm.$t('patient.password.newConfirm')},domProps:{"value":(_vm.passwordConfirm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.passwordConfirm=$event.target.value}}}),_c('span',{staticClass:"notice-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"input-submit"},[_c('input',{staticClass:"btn",attrs:{"type":"submit"},domProps:{"value":_vm.$t('patient.password.update')}})])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }