<template>
    <div class="my-account">
        <router-link class="back" :to="{ name: 'PathologyList'}"></router-link>
        <h1>{{ $t('patient.personnalInfos')}}</h1>
        <AccountUpdate></AccountUpdate>
        <div class="sep"></div>
        <h1>{{ $t('patient.changePassword')}}</h1>
        <PasswordChange></PasswordChange>
         <div class="sep"></div>
        <h1>{{ $t('patient.agreementTitle')}}</h1>
        <div class="agreement-list" v-if="agreements.length > 0">
            <div class="agreement-item" v-for="(item, idx) in agreements" :key="idx" @click="downloadFile(item.agreements_id, item.agreements_name)">
                <img class="agreement-icone" src="./../../assets/icon-pdf.png" />
                <div class="agreement-name" > {{ item.agreements_name }}</div>
            </div>
        </div>
        <div class="no-agreement" v-else>
            <p>{{ $t('patient.agreementEmpty') }}</p>
        </div>
        <div class="sep"></div>
        <h1>{{ $t('patient.deleteAccount')}}</h1>
        <div class="delete-container">
            <p class="delete-account" v-html="$t('patient.deleteAccountText')"></p>
            <div class="btn" @click="displayModalConfirm = true">{{ $t('patient.deleteAccountBtn')}}</div>
        </div>
        <ModalConfirm
            v-if="displayModalConfirm"
            :contentModal="$t('patient.deleteAccountConfirm')"
            v-on:close="closeConfirm"
            v-on:confirm="confirmDelete"
        >
        </ModalConfirm>
        <div class="modal" v-if="isLoading">
            <Loader></Loader>
        </div>
    </div>
</template>

<script>
import PasswordChange from './../../components/patient/PasswordChange';
import AccountUpdate from './../../components/patient/AccountUpdate';
import ModalConfirm from './../../components/ModalConfirm'
import axios from 'axios'
import store from './../../store'
import router from './../../router'
import Loader from './../../components/Loader'

export default {
    name : 'MyAccount',
    components : {
        PasswordChange,
        AccountUpdate,
        ModalConfirm,
        Loader
    },
    data() {
        Loader
        return {
            displayModalConfirm : false,
            isLoading : false,
            agreements : []
        }
    },
    methods : {
        closeConfirm() {
            this.displayModalConfirm = false
        },
        confirmDelete() {
            this.isLoading = true
            this.displayModalConfirm = false
            let config = {
                validateStatus: () => true,
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
            }

            let vm = this

            axios
                .delete(store.getters.getURL +"users/"+ store.state.USER.infos.id, config)
                .then(function (response) {
                    vm.isLoading = false
                    if(response.status === 200) {
                        store.dispatch('addNotif', {type : 1, content : 11})
                        
                        setTimeout(function(){
                            store.commit('logout')
                            router.push('/')
                        }, 3000)
                    }else {
                        store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG]})
                    }
                })
                .catch((err) => {
                    store.dispatch('addNotif', {type : 2, content : err})
                });
        },
        downloadFile(id, name) {
            let config = {
                validateStatus: () => true,
                responseType: 'blob',
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
            }

            this.pdfFileName = name

            let vm = this
             axios
                .get(store.getters.getURL+'agreement/download_pdf/' + id ,  config)
                .then(function(response) {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', vm.pdfFileName + '.pdf');
                    document.body.appendChild(fileLink);
                
                    fileLink.click();
                })
        },
    },
    mounted() {
        let config = {
             validateStatus: () => true,
        }
        if(store.getters.getToken) {
            config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
        }

        let vm = this

        axios
            .get(store.getters.getURL +"agreement/" + store.state.USER.infos.id, config)
            .then(function (response) {
                vm.agreements = response.data.list
            })
            .catch((err) => {
                store.dispatch('addNotif', {type : 2, content : err})
            });
    }
}
</script>

<style lang="scss" scoped>
    .my-account {
        background: #fff;
        border-radius:10px;
        box-shadow: 0 2px 5px rgba(0,0,0,0.5);
        margin:80px auto 80px auto;
        padding: 20px 40px;
        width:$boxWidth;
        max-width: calc(90% - 80px);
        position:relative;
        min-height: calc(100vh - 351px);
        position: relative;
    }

    h1 {
        text-align: center;
        color:$mainColor;
        font-size:25px;
        letter-spacing: 1px;
        font-family: $fontBlack;
    }

    .sep {
        margin:40px auto;
        width:300px;
        max-width: 50%;
        height:1px;
        background: $mainColor;
    }

    .delete-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 800px;
        max-width: 90%;
        margin: 40px auto;
    }

    .delete-container .btn {
        margin-top:40px;
        width: 200px;
    }

    .back {
        position:absolute;
        top:15px;
        left:40px;
        height:40px;
        width: 40px;
        background-image: url('./../../assets/back.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .agreement-list {
        width: 800px;
        max-width: 90%;
        margin: 40px auto;
        display:flex;
        flex-wrap: wrap;
    }

    .agreement-item {
        width:23%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin:10px 1%;
        cursor: pointer;
    }

    .agreement-item:hover .agreement-name {
        color:$mainColor;
    }

    .no-agreement p {
        text-align: center;
        margin:20px auto;
    }

    .agreement-icone {
        width:80px;
        height:auto;
    }

    .agreement-name {
        text-align: center;
        transition:0.5s;
    }

    @media screen and (max-width:768px) {
        h1 {
            font-size:18px;
        }

        .my-account {
            margin:40px auto;
            padding:60px 20px 40px 20px;
            max-width: calc(90% - 20px);
        }

        .back {
            left:calc(50% - 20px);
        }

        p {
            text-align: justify;
        }

        .delete-container {
            margin:20px 0;
            width: 100%;
            max-width: 100%;
        }

        .agreement-item {
            width:48%;
        }
        
    }
</style>