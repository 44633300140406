<template>
    <div class="login-form">
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)">
                <div class="row">
                    <div class="col50">
                        <ValidationProvider  class="input-default"  rules="required|max:50" v-slot="{ errors }">
                            <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="firstname" :placeholder=" $t('patient.form.firstname')" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="col50">
                        <ValidationProvider  class="input-default"  rules="required|max:50"  v-slot="{ errors }">
                            <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="lastname" :placeholder=" $t('patient.form.lastname')" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col50">
                        <ValidationProvider  class="input-default"  rules="required|max:50|email" vid="email" v-slot="{ errors }">
                            <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="email" :placeholder=" $t('patient.form.email')" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="col50">
                        <ValidationProvider  class="input-default"  rules="required|max:50|email|confirmed:email"  v-slot="{ errors }">
                            <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="emailConfirm" :placeholder=" $t('patient.form.emailConfirm')" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col50">
                        <ValidationProvider  class="input-default"  v-slot="{ errors }">
                            <input type="date" :class="{ 'input-error' : errors[0]}"  v-model="birthday" :placeholder=" $t('patient.form.birthday')" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="col50">
                        <ValidationProvider  class="input-default" rules="max:25"  v-slot="{ errors }">
                            <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="phone" :placeholder=" $t('patient.form.phone')" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">       
                    <div class="col50">
                        <ValidationProvider  class="input-default"  rules="required" v-slot="{ errors }">
                            <select v-model="sex" :class="{ 'input-error' : errors[0]}" name="sex">
                                <option value="-1" disabled>{{ $t('patient.form.sex') }}</option>
                                <option value="2">Femme</option> 
                                <option value="1">Homme</option>
                            </select>
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="col50">
                        <ValidationProvider  class="input-default"  v-slot="{ errors }">
                            <select v-model="status" :class="{ 'input-error' : errors[0]}">
                                <option value="-1" disabled>{{ $t('patient.form.situation') }}</option>
                                <option v-for="(item, idx) in statusList" :key="idx" :value="item.marital_status_id">{{ JSON.parse(item.marital_status_name)[currentLang]}}</option> 
                            </select>
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="input-submit">
                    <input type="submit" class="btn" :value="$t('patient.form.update')" />
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import { ValidationProvider,  ValidationObserver } from 'vee-validate';
import axios from 'axios'
import store from './../../store'

export default {
    name : 'AccountUpdate',
    components : {
      ValidationProvider,
      ValidationObserver
    },
    data() {
        return {
            firstname : store.state.USER.infos.firstname,
            lastname : store.state.USER.infos.lastname,
            birthday: store.state.USER.infos.birthday,
            status : store.state.USER.infos.marital_status,
            email : store.state.USER.infos.email,
            emailConfirm : store.state.USER.infos.email,
            phone: store.state.USER.infos.phone,
            sex : store.state.USER.infos.sex,
            statusList : []
        }
    },
    methods : {
        submit() {
            let params = {
                firstname : this.firstname,
                lastname : this.lastname,
                email :this.email,
                emailConfirm : this.emailConfirm,
                birthday : this.birthday,
                marital_status : this.status,
                id : store.state.USER.infos.id,
                phone : this.phone,
                sex : this.sex,
                role_id : 4
            }

            let config = {
                validateStatus: () => true,
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
            }

            axios
                .put(store.getters.getURL +"users/" + params.id, params, config)
                .then(function (response) {
                    if(response.status === 200) {
                        let user = {
                            id : params.id,
                            firstname : params.firstname,
                            lastname : params.lastname,
                            email : params.email,
                            birthday : params.birthday,
                            sex : params.sex,
                            marital_status : params.marital_status,
                            phone : params.phone
                        }
                        store.commit('updateUser', user)
                        store.dispatch('addNotif', {type : 1, content : 9})
                    }else {
                        store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG]})
                    }
                    
                })
                .catch((err) => {
                    store.dispatch('addNotif', {type : 2, content : err})
                });
        }
    },
    mounted() {
        let config = {
            validateStatus: () => true,
        }
        if(store.getters.getToken) {
            config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
        }

        let vm = this

        axios
            .get(store.getters.getURL +"marital_status", config)
            .then(function (response) {
                vm.statusList = response.data
            })
            .catch((err) => {
                store.dispatch('addNotif', {type : 2, content : err})
            });
    },
    computed : {
        currentLang() {
            return this.$store.state.LANG
        }
    }
}
</script>

<style lang="scss" scoped>
    form {
        width:800px;
        max-width: 90%;
        margin:40px auto;
        display: flex;
        flex-direction: column;
    }

    .col50 {
        width:46%;
        margin:0 2%;
    }

    .input-submit {
        margin-top:10px;
        display: flex;
        justify-content: center;
    }

    .btn {
        width:200px;
    }


    @media screen and (max-width:768px) {
        .login-form .row {
            flex-direction: column;
        }

        .col50, .col100 {
            width:100%;
        }
    }


</style>