<template>
    <div class="login-form">
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)">
                <ValidationProvider  class="input-default"  rules="required|max:16|min:8" v-slot="{ errors }">
                    <input type="password" :class="{ 'input-error' : errors[0]}"  v-model="passwordOld" :placeholder=" $t('patient.password.old')" />
                    <span class="notice-error">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider  class="input-default"  rules="required|max:16|min:8|rgpd_password" ref="pwd" vid="pwd" v-slot="{ errors }">
                    <input type="password" :class="{ 'input-error' : errors[0]}"  v-model="password" :placeholder=" $t('patient.password.new')" />
                    <span class="notice-error">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider  class="input-default"  rules="required|max:16|min:8|rgpd_password|confirmed:pwd" v-slot="{ errors }">
                    <input type="password" :class="{ 'input-error' : errors[0]}"  v-model="passwordConfirm" :placeholder=" $t('patient.password.newConfirm')" />
                    <span class="notice-error">{{ errors[0] }}</span>
                </ValidationProvider>
                <div class="input-submit">
                    <input type="submit" class="btn" :value="$t('patient.password.update')" />
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import { ValidationProvider,  ValidationObserver } from 'vee-validate';
import axios from 'axios'
import store from './../../store'

export default {
    name : 'PasswordChange',
    components : {
      ValidationProvider,
      ValidationObserver
    },
    data() {
        return {
            passwordOld : null,
            password : null,
            passwordConfirm : null
        }
    },
    methods : {
        submit() {
            let params = {
                email : store.state.USER.infos.email,
                passwordOld : this.passwordOld,
                password : this.password,
                password_confirmation : this.passwordConfirm
            }

            let config = {
                validateStatus: () => true,
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
            }

            axios
                .post(store.getters.getURL +"password/change_password", params, config)
                .then(function (response) {
                    if(response.status === 200) {
                        store.dispatch('addNotif', {type : 1, content : 10})
                    }else {
                        store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG]})
                    }
                    
                })
                .catch((err) => {
                    store.dispatch('addNotif', {type : 2, content : err})
                });
        }
    }
}
</script>

<style lang="scss" scoped>
    form {
        width:600px;
        max-width: 90%;
        margin:40px auto;
        display: flex;
        flex-direction: column;
    }

    .input-submit {
        margin-top:10px;
        display: flex;
        justify-content: center;
    }

    .btn {
        width:200px;
    }
</style>